<template>
  <div class="home">
    <Header />
    <AboutUs />
    <BusinessOffer />
    <IndividualOffer />
    <Contact />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import AboutUs from "@/components/AboutUs.vue";
import IndividualOffer from "@/components/IndividualOffer.vue";
import BusinessOffer from "@/components/BusinessOffer.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "Home",

  components: {
    Header,
    AboutUs,
    IndividualOffer,
    BusinessOffer,
    Contact,
  },
};
</script>
