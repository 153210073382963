<template>
  <section class="relative lg:py-16 bg-white">
    <div
      class="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
      aria-hidden="true"
    />
    <div
      class="max-w-7xl mx-auto bg-tomdar-orange-100 lg:bg-transparent lg:px-4 pt-12 sm:pt-16 lg:pt-0"
    >
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent"
        >
          <div class="absolute inset-x-0 h-1/2 lg:hidden" aria-hidden="true" />
          <div
            class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0"
          >
            <div
              class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1"
            >
              <img
                class="object-cover object-center rounded-3xl shadow-2xl"
                src="../assets/laundry.jpg"
                alt="Złożone pranie niesione na rękach"
              />
            </div>
          </div>
        </div>

        <div
          class="relative bg-tomdar-orange-100 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center"
        >
          <div
            class="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block"
            aria-hidden="true"
          >
            <svg
              class="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-tomdar-orange-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
            <svg
              class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-tomdar-orange-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
          <div
            class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-4"
          >
            <h2
              class="mb-8 text-3xl font-extrabold text-wa-tomdar-black-100"
              id="join-heading"
            >
              Oferta indywidualna
            </h2>
            <ul>
              <li class="flex justify-items-start items-center">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="h-5 w-5 text-tomdar-orange-400"
                    aria-hidden="true"
                  />
                </div>
                <p class="ml-3 text-lg text-wa-tomdar-black-100">
                  Czyszczenie chemiczne i&nbsp;wodne
                </p>
              </li>
              <li class="mt-7 flex justify-items-start items-center">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="h-5 w-5 text-tomdar-orange-400"
                    aria-hidden="true"
                  />
                </div>
                <p class="ml-3 text-lg text-wa-tomdar-black-100">
                  System <span class="font-bold">lagoon Advanced Care</span>
                </p>
              </li>
              <li class="mt-7 flex justify-items-start items-center">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="h-5 w-5 text-tomdar-orange-400"
                    aria-hidden="true"
                  />
                </div>
                <p class="ml-3 text-lg text-wa-tomdar-black-100">
                  Pranie bielizny i&nbsp;magiel
                </p>
              </li>
              <li class="mt-7 mb-12 flex justify-items-start items-center">
                <div class="flex-shrink-0">
                  <CheckCircleIcon
                    class="h-5 w-5 text-tomdar-orange-400"
                    aria-hidden="true"
                  />
                </div>
                <p class="ml-3 text-lg text-wa-tomdar-black-100">
                  Pranie zasłon, firan i&nbsp;dywanów
                </p>
              </li>
            </ul>
            <div class="sm:flex sm:justify-start">
              <div class="rounded-md shadow">
                <router-link
                  :to="{ name: 'PriceList' }"
                  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-wa-tomdar-black-100 bg-white hover:bg-gray-50 md:py-2 md:text-base md:px-4 transition duration-150 ease-in-out"
                >
                  Sprawdź cennik
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";

export default {
  name: "IndividualOffer",

  components: { CheckCircleIcon },
};
</script>

<style scoped></style>
