<template>
  <Disclosure
    as="nav"
    class="sticky top-0 z-50 bg-white shadow blur-sm"
    v-slot="{ open }"
  >
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-24">
        <div class="flex">
          <div class="px-2 pb-2 pt-4 flex-shrink-0">
            <router-link :to="{ name: 'Home' }">
              <img
                class="h-16"
                src="../assets/logo_tomdar.svg"
                alt="Logo firmy TOMDAR"
              />
            </router-link>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <a
              href="/#about-us"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              O&nbsp;nas
            </a>
            <a
              href="/#offer"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Oferta
            </a>
            <router-link
              :to="{ name: 'PriceList' }"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Cennik
            </router-link>
            <!-- <a
              href="/#clients"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Klienci
            </a> -->
            <a
              href="/#contact"
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Kontakt
            </a>
          </div>
        </div>
        <div class="hidden md:ml-6 md:flex md:items-center">
          <div class="flex-shrink-0">
            <MailIcon
              class="h-6 w-6 text-tomdar-orange-100"
              aria-hidden="true"
            />
          </div>
          <div class="ml-1 text-base text-gray-500">
            <a href="mailto:tomdar@onet.com.pl">tomdar@onet.com.pl</a>
          </div>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 rounded-md text-tomdar-blue-200 hover:text-tomdar-blue-100 hover:bg-tomdar-blue-300 focus:outline-none"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <DisclosureButton
          as="a"
          href="/#about-us"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          >O nas</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="/#offer"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          >Oferta</DisclosureButton
        >
        <DisclosureButton
          as="a"
          href="/cennik"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          >Cennik</DisclosureButton
        >
        <!-- <DisclosureButton
          as="a"
          href="/#clients"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          >Klienci</DisclosureButton
        > -->
        <DisclosureButton
          as="a"
          href="/#contact"
          class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          >Kontakt</DisclosureButton
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

import { MenuIcon, XIcon, MailIcon } from "@heroicons/vue/outline";

export default {
  name: "NavBar",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
    MailIcon,
  },
};
</script>

<style scoped></style>
